import axios from 'axios'
import request from '@/utils/request'

// 文档 https://i.goolboo.com/wiki/pages/viewpage.action?pageId=1081615
// 公共封装方法 尽量把所有服务放一块，如有需要sellServer自定义
const fetch = (path = '', params = {}, restConfig = {
    method: 'post',
    'Content-Type': 'application/json'
}) => request('sellServer', path, {
    params,
    ...restConfig
})

export default {
    getSpuList (params) {
        // 获取商品列表
        return fetch('/sell-spu/sell-spu-page', params, {
            dataType: 'form'
        })
    },
    importDataChkhead (query) {
        // 4.2数据导入第一步，检查excel列名
        return axios.fetch('sellServer', '/sell-IndicatorData/import-data-chkhead', query, 'FormData')
    },
    getValueIndicator (params) {
        // 4.10获取同分组下的所有数值指标
        return fetch('/sell-IndicatorData/get-value-indicator', params, {
            dataType: 'form'
        })
    },
    getValueAllIndicator (params) {
        // 4.10获取同分组下的所有数值指标
        return fetch('/sell-IndicatorData/get-all-indicator', params)
    },
    getCategoryList (query) {
        // 4.4获取运营数据类型列表
        return axios.fetch('sellServer', '/sell-indicator-category/get-indicator-category-list', query)
    },
    getIndicatorList (query) {
        // 4.1根据数据类型获取指标库字段名称列表
        return axios.fetch('sellServer', '/sell-IndicatorData/get-indicator-list', query)
    },
    iportDataChkdata (query) {
        // 运营数据导入第二步，检查excel数据
        return axios.fetch('sellServer', '/sell-IndicatorData/import-data-chkdata', query, {
            'Content-Type': 'application/json'
        })
    },
    iportData (query) {
        // 运营数据导入第二步，检查excel数据
        return axios.fetch('sellServer', '/sell-IndicatorData/import-data', query, {
            'Content-Type': 'application/json'
        })
    },
    getSellVisitSourceTree (query) {
        // 4.3获取流量来源树形结构数据
        return axios.fetch('sellServer', '/sell-visit-source/sell-visit-source-tree', query, {
            'Content-Type': 'application/json'
        })
    },
    sellVisitSourceCreate (query) {
        return axios.fetch('sellServer', '/sell-visit-source/sell-visit-source-create', query, {
            'Content-Type': 'application/json'
        })
    },
    getComment (params) {
        return fetch('/sell-comment/get-comment', params)
    },
    setComment (params) {
        return fetch('/sell-comment/comment-create', params)
    },
    getAssociateComment (params) {
        return fetch('/sell-comment/get-associate-comment', params)
    },
    createFavorite (params) {
        // 5.4关注商品
        return fetch('/sell-employee-favorite/favorite-create', params)
    },
    deleteFavorite (params) {
        // 5.5取消关注商品
        return request('sellServer', '/sell-employee-favorite/favorite-delete', {
            params,
            dataType: 'form'
        })
    },
    favoriteList (params) {
        // 5.3获取渠道下关注的商品列表
        return request('sellServer', '/sell-employee-favorite/spu-channel-favorite-list', {
            params,
            dataType: 'form',
            customConfig: {
                openLoading: true
            }
        })
    },
    getChannelSpuList (params) {
        // 3.11获取渠道下spu列表分页
        return request('sellServer', '/sell-spu/sell-channel-spu-page', {
            params,
            dataType: 'form'
        })
    },
    getSpuTableData (params) {
        // 3.11获取渠道下spu列表分页
        return fetch('/sell-relation-analysis/spu-data-statistics', params)
    },
    getSpuChartData (params) {
        // 5.2获取竞品分析图表数据
        return fetch('/sell-relation-analysis/spu-visit-chart-data', params)
    },
    getSellVisit (params) {
        // 4.9根据流量来源层级获得流量来源列表
        return fetch('/sell-visit-source/get-visit-by-level', params)
    },
    getCategoryByChannel (params) {
        // 6.1根据渠道ID获取相关的平台品类列表
        return fetch('/sell-spu/category-by-channel', params)
    },
    getBrandByChannelCategory (params) {
        // 6.2根据渠道ID和平台品类ID获得相关品牌列表
        return fetch('/sell-spu/brand-by-channel-category', params)
    },
    getRelationBrand (params) {
        // 6.3根据渠道ID、平台品类ID和品牌ID获得相关竞品品牌列表
        return fetch('/sell-spu/get-relation-brand', params)
    },
    getSpuInfoList (params) {
        // 6.4根据渠道ID、平台品类ID、品牌ID、竞品品牌ID、定位获得相关商品信息
        return fetch('/sell-spu/spu-info-list', params)
    },
    getActions (params) {
        // 7.1分页获取运营动作基本库数据列表
        return fetch('/sell-action/action-page', params, {
            dataType: 'form'
        })
    },
    getActionDetail (params) {
        // 7.3获取运营动作基本库和对应的监控指标数据
        return fetch('/sell-action/action-indicator-detail', params)
    },
    saveActionIndicator (params) {
        // 7.4新建或者更新运营动作
        return fetch('/sell-action/save-action-indicator', params, {
            customConfig: {
                openLoading: true
            }
        })
    },
    removeActionRecord (params) {
        // 7.6删除运营动作监控指标
        return fetch('/sell-action/delete-action-and-indicator', params)
    },
    getActionRecord (params) {
        // 7.6分页获取运营动作基本库数据列表
        return fetch('/sell-action-record/action-record-page', params, {
            dataType: 'form'
        })
    },
    getIndicator (params) {
        // 7.9获取运营动作的监控指标数据
        return fetch('/sell-action-record/get-monitor-indicator', params)
    },
    updateRecord (params) {
        // 7.8更新运营动作记录数据
        return fetch('/sell-action-record/action-record-update', params)
    },
    getAllActions (params) {
        // 7.2获取运营动作基本库所有数据
        return fetch('/sell-action/all-action-list', params)
    },
    saveActionRecord (params) {
        // 7.4新建或者更新运营动作
        return fetch('/sell-action-record/action-record-create', params, {
            customConfig: {
                openLoading: true
            }
        })
    }
}
