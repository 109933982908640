import axios from '@/utils/https'
import request from '@/utils/request'

const fetch = (path = '', params = {}, restConfig = {
    method: 'post',
    'Content-Type': 'application/json'
}) => request('commonServer', path, {
    params,
    ...restConfig
})
export default {
    getDateExample () {
        // 参考例子
        return axios.fetch()
    },
    // 数据字典
    getDictionary (groups) {
        return fetch('/dict/getItemViewsByGroup', {
            groups
        }, {
            dataType: 'form'
        })
    },
    // https://i.goolboo.com/wiki/pages/viewpage.action?pageId=1082157
    getUserShortcuts (query) {
        // 1. 获取当前用户的快捷配置列表
        return request('commonServer', '/common/getUserShortcuts', {
            params: query,
            dataType: 'form'
        })
    },
    saveUserShortcut (query) {
        // 2. 保存当前用户的快捷配置
        return request('commonServer', '/common/saveUserShortcut', {
            params: query,
            dataType: 'form'
        })
    }
}
